import axios from "axios";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/scss/main.css";
import { onMessageListener } from "./firebase";
import { persistor, Store } from "./redux/store";
import RootRouter from "./routes/RootRouter";
import { connectToServerSocket } from "./utils/socketUtils";
export const axiosApiInstance = axios.create();

function App() {


  useEffect(() => {
    notifyMe();
  }, [])


  function notifyMe() {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          // …
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }



  onMessageListener()
    .then((payload) => {
      // setNotification({title: payload.notification.title, body: payload.notification.body})
      // setShow(true);
      // console.log(payload);
      // console.log("Received background message ", payload);

      // const notificationTitle = payload.notification.title;
      // const notificationOptions = {
      //   body: payload.notification.body,
      // };
      new Notification(payload.data.title, { body: payload.data.description })
      // toastSuccess(payload.data.description);
    })
    .catch((err) => console.log("failed: ", err));
  const handleSocketConnect = async () => {
    try {

      console.log("Connecting socket");
      await connectToServerSocket();
    } catch (error) {
      console.error(error)
    }
  };
  useEffect(() => {
    handleSocketConnect();
  }, []);

  return (
    <Provider store={Store}>
      <PersistGate loading={<h1>Loading...</h1>} persistor={persistor}>
        <RootRouter />
        <Toaster />
      </PersistGate>
    </Provider>
  );
}

export default App;
