import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addMessage,
  getMessage,
  uploadFile,
} from "../../../services/Message.service";
import { getDecodedToken } from "../../../services/users.service";
import {
  joinRoom,
  listenToMessages,
  sendMessage,
} from "../../../utils/socketUtils";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import { toastError } from "../../Utility/ToastUtils";
import { generateFilePath } from "../../Utility/utils";

export default function SingleChat() {
  const [chatArr, setChatArr] = useState([]);
  const params = useParams();
  const [message, setMessage] = useState("");

  const chatRef = useRef(null)

  const handleGetChatByChatId = async () => {
    try {
      let { data: res } = await getMessage(params.id);
      if (res.data) {
        console.log(res.data);
        setChatArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleOnint = async () => {
    try {
      let tokenObj = await getDecodedToken();
      joinRoom(params.id);
      listenToMessages((data) => {
        console.log(data);
        if (tokenObj?.userId != data.senderId) {
          let tempObj = {
            ...data,
            chatId: data.roomId,
            message: data.message,
            sentByMe: false,
            userId: data.senderId,
          };
          setChatArr((prevState) => {
            prevState.push(tempObj);
            return [...prevState];
          });
        }
        // readChatMessage();
        console.log("ASdADsADs");
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSendMessageByChatId = async () => {
    try {
      if (message == "") {
        return;
      }
      let obj = {
        message,
      };
      let { data: res } = await addMessage(params.id, obj);
      if (res.success) {
        setMessage("");
        handleGetChatByChatId();
      }
    } catch (err) {
      toastError(err);
    }
  };

  const sendMessageToSocket = async () => {
    try {
      let tokenObj = await getDecodedToken();
      await sendMessage({ roomId: params.id, message });
      let tempArr = [...chatArr];
      tempArr.push({
        message,
        chatId: params.id,
        userId: tokenObj?.userId,
        sentByMe: true,
      });
      setChatArr([...tempArr]);
      setMessage("");
    } catch (error) {
      toastError(error);
    }
  };

  const handleDocumentPicker = async (e) => {
    try {
      console.log(e.target.files[0], "e.target.files[0]");
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        let { data: res } = await uploadFile(formData);
        if (res) {
          console.log(res.data, "FILE");
          handleFileSendWithSocket(res.data);
        }
      }
      // console.log(file);
    } catch (error) {
      toastError(error);
      // handleError(error);
    }
  };

  const handleFileSendWithSocket = async (fileObj) => {
    try {
      console.log("@@@@", fileObj);
      let messageType = fileObj?.mimetype.split("/")[0];
      console.log(messageType, "messageType");
      let tokenObj = await getDecodedToken();
      await sendMessage({
        roomId: params.id,
        message: fileObj?.filename,
        messageType: messageType,
      });
      let tempArr = [...chatArr];
      tempArr.push({
        message: fileObj?.filename,
        messageType: messageType,
        chatId: params.id,
        userId: tokenObj?.userId,
        sentByMe: true,
      });
      setChatArr([...tempArr]);
      setMessage("");
      
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    handleOnint();
    handleGetChatByChatId();
  }, []);

  const handleClick = () => {
    document.getElementById("fileUpload").click();
  };



  useEffect(()=> {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [chatArr, chatRef])
  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <DashboardTable>
                <div ref={chatRef} style={{ display: "flex", flexDirection: "column" , overflowY:"scroll", maxHeight:"70vh"}}>
                  {chatArr &&
                    chatArr.length > 0 &&
                    chatArr.map((el, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            alignSelf: el.sentByMe ? "flex-end" : "flex-start",
                            textAlign: el.sentByMe ? "right" : "left",
                            width: "50%",
                            flexWrap: "wrap",
                            wordBreak: "break-all",
                            margin: "10px 0px",
                            padding: "15px 15px 25px 15px",
                            position: "relative",
                            borderRadius: el.sentByMe
                              ? "15px 15px 0px 15px"
                              : "15px 15px 15px 0px",
                            backgroundColor: el.sentByMe
                              ? "rgba(132, 163, 163,1)"
                              : "rgba(166, 173, 173,0.3)",
                          }}
                        >
                          {el.messageType === "image" ? (
                            <a
                              href={generateFilePath(el.message)}
                              alt="file_image"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Click Here To Open
                            </a>
                          ) : (
                            el?.message
                          )}

                          <div
                            style={{
                              position: "absolute",
                              fontSize: 12,
                              bottom: 0,
                              left: el.sentByMe == false ? 15 : "83%",
                            }}
                          >
                            {moment(el.createdAt).format("DD-MM-YYYY")}
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}>
                  <textarea
                    type="text"
                    className="form-control"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />

                  <CustomButton
                    isBtn
                    iconName="fa-solid fa-paperclip"
                    btnName=""
                    extraClass={"mx-3 px-3"}
                    ClickEvent={(e) => {
                      e.preventDefault();
                      handleClick();
                    }}
                  />
                  <CustomButton
                    isBtn
                    iconName="fa-solid fa-plus"
                    btnName="Send"
                    ClickEvent={(e) => {
                      e.preventDefault();
                      sendMessageToSocket();
                    }}
                  />
                </div>

                <input
                  style={{ display: "none" }}
                  type="file"
                  id={"fileUpload"}
                  accept="image/*"
                  onChange={(e) => handleDocumentPicker(e)}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
