import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  SetUserPIObj,
  userPiDelete,
  usersPiGet,
} from "../../redux/actions/UserPi/userPi.actions";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import { AddModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
export default function UserOrder() {
  const dispatch = useDispatch();

  const userPiArr = useSelector((state) => state.userPi.userPi);

  const [displayUserPiArr, setDisplayUserPiArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [query, setQuery] = useState("");

  const [dateFilter, setDateFilter] = useState("");

  useEffect(() => {
    if (userPiArr) {
      setDisplayUserPiArr([...userPiArr]);
      setMainArr([...userPiArr]);
    }
  }, [userPiArr]);

  useEffect(() => {
    let query = "";
    if (dateFilter) {
      let baseDate = new Date(dateFilter);
      let startDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate()
      );
      let endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 1
      );
      endDate.setTime(endDate.getTime() - 1); // setting time as 23:59
      query =
        query +
        `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    }
    dispatch(usersPiGet(query));
  }, [dateFilter]);

  const handleEdit = (row) => {
    dispatch(SetUserPIObj(row));
  };

  const handleDeleteById = (id) => {
    dispatch(userPiDelete(id));
  };

  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);

  const checkDate = (dateValue) => {
    let dateStart = new Date();
    dateStart.setHours(0, 0, 0, 0);
    let dateEnd = new Date();
    dateEnd.setHours(23, 59, 59, 59);
    let tempDateValue = new Date(dateValue).getTime();

    if (
      dateStart.getTime() < tempDateValue &&
      dateEnd.getTime() > tempDateValue
    ) {
      return true;
    } else {
      return false;
    }
  };

  const product_sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "CREATED" && checkDate(row.createdAt),
          style: {
            backgroundColor: "rgba(63, 195, 128, 0.9)",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => !(row.status == "CREATED" && checkDate(row.createdAt)),
          style: {
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "User Name",
      cell: (row) => (
        <div style={{ position: "relative" }}>
          {
            row.readByAdmin == false && 
          <div
            style={{
              height: 10,
              width: 10,
              borderRadius: 10,
              backgroundColor: "red",
              position: "absolute",
              top: -10,
              left: -10,
            }}
          ></div>

          }
          {row?.userObj?.name}
        </div>
      ),
      width: "15%",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "CREATED" && checkDate(row.createdAt),
          style: {
            backgroundColor: "rgba(63, 195, 128, 0.9)",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => !(row.status == "CREATED" && checkDate(row.createdAt)),
          style: {
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Grand Total Cost",
      selector: (row) => `INR ${row?.grandTotal}`,
      width: "15%",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "CREATED" && checkDate(row.createdAt),
          style: {
            backgroundColor: "rgba(63, 195, 128, 0.9)",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => !(row.status == "CREATED" && checkDate(row.createdAt)),
          style: {
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Ordered On",
      selector: (row) => `${new Date(row.createdAt).toDateString()}`,
      width: "10%",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "CREATED" && checkDate(row.createdAt),
          style: {
            backgroundColor: "rgba(63, 195, 128, 0.9)",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => !(row.status == "CREATED" && checkDate(row.createdAt)),
          style: {
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Last Updated On",
      selector: (row) =>
        `${new Date(row.updatedAt).toDateString()} | ${new Date(
          row.updatedAt
        ).getHours()}:${new Date(row.updatedAt).getMinutes()}`,
      width: "14%",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "CREATED" && checkDate(row.createdAt),
          style: {
            backgroundColor: "rgba(63, 195, 128, 0.9)",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => !(row.status == "CREATED" && checkDate(row.createdAt)),
          style: {
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Status",
      selector: (row) => `${row.status}`,
      width: "15%",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "CREATED" && checkDate(row.createdAt),
          style: {
            backgroundColor: "rgba(63, 195, 128, 0.9)",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => !(row.status == "CREATED" && checkDate(row.createdAt)),
          style: {
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Action",
      width: "15%",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "CREATED" && checkDate(row.createdAt),
          style: {
            backgroundColor: "rgba(63, 195, 128, 0.9)",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => !(row.status == "CREATED" && checkDate(row.createdAt)),
          style: {
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            edit
            deletePath="/User-Order/View-All"
            onDeleteClick={() => handleDeleteById(row._id)}
            isRedirected={true}
            onEditClick={() => handleEdit(row)}
            editPath="/User-Order/Create"
            // detail
            // detailClick={(e) => {
            //   e.preventDefault();
            //   setModalBox(true);
            //   setModalType("show-product");
            //   setModalName(row.Name);
            // }}
          />

          <AddModal
            ModalBox={ModalBox}
            setModalBox={setModalBox}
            name={ModalName}
            ModalType={ModalType}
          />
        </>
      ),
    },
  ];

  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter((el) =>
      `${el?.userObj?.name}`
        .toLowerCase()
        .includes(`${queryValue}`.toLowerCase())
    );
    setDisplayUserPiArr([...tempArr]);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1">User Order List</h5>
                <div className="d-flex align-items-center gap-3">
                  <input
                    type="date"
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                    className="form-control"
                  />

                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD NEW ORDER"
                    path="/User-Order/Create"
                  />
                  <SearchBox
                    query={query}
                    setQuery={handleSearch}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={product_sale_columns}
                  data={
                    displayUserPiArr && displayUserPiArr.length > 0
                      ? displayUserPiArr
                      : []
                  }
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
