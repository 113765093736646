import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteUser, emailLoginApi, sendOtpEmailApi, verifyEmailOtpApi } from "../../services/users.service";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";

function UserDelete({ Unauth }) {
    const [password, setpassword] = useState("");
    const [email, setEmail] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [userDelete, setuserDelete] = useState(false);
    const [otp, setotp] = useState("");
    const [passwordIcon, setpasswordIcon] = useState("ion-eye");
    const dispatch = useDispatch();
    const [userID, setuserID] = useState("");


    const handleLogin = async () => {
        try {
            let obj = {
                email,
            };
            let { data: res } = await emailLoginApi(obj);
            let decodedToken = await jwtDecode(res.token);
            console.log(decodedToken, "decodedToken");
            setuserID(decodedToken.userId)
            setuserDelete(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteUser = async () => {
        try {

            let { data: res } = await deleteUser(userID);
            if (res.message) {
                toastSuccess(res.message);
                window.location.reload();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async () => {
        try {
            if (email == "") {
                toastError("Email/Phone is mandatory")
                return;
            }
            if (password == "") {
                toastError("Password is mandatory")
                return;
            }
            let obj = {
                email,
                password
            };
            let { data: res } = await sendOtpEmailApi(obj);
            if (res.message) {
                // toastSuccess(res.message);
                setuserID(res.data)
                setuserDelete(true);
            } else {
                toastError(res.message);
            }
        } catch (error) {
            console.log(error, "error")
            toastError(error)
            console.log(error);
        }
    };

    const handleVerfiy = async () => {
        let obj = {
            email,
            otp,
        };
        try {
            let { data: res } = await verifyEmailOtpApi(obj);
            if (res.message) {
                toastSuccess(res.message);
                await handleLogin()
            } else {
                toastError(res.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <main className="w-100">
            <section
                className={
                    Unauth
                        ? "product-category d-flex align-items-center"
                        : "product-category"
                }
                style={{ minHeight: "85vh" }}
            >
                <div className="container-fluid p-0">
                    {Unauth && (
                        <div className="text-center col-lg-4 mx-auto mb-4">
                            <div className="main-logo mx-auto mb-4">
                                {/* <img style={{ height: 130 }} src={images.logo} alt="" /> */}
                            </div>
                            <h5>Nikky Pore,</h5>
                            <h5>Please login to your account</h5>
                        </div>
                    )}
                    <DashboardBox className={Unauth ? "col-lg-4 mx-auto" : "col-lg-8"}>
                        {!Unauth && <h5 className="blue-1 mb-4">Login To Your Account</h5>}
                        <form className="form row">
                            {
                                !userDelete ? (
                                    <>
                                        <div className="col-12 mb-3">
                                            <label>Enter Email</label>
                                            <div className="form-input">
                                                <input
                                                    className="form-control"
                                                    name="name"
                                                    value={email}
                                                    onChange={(event) => setEmail(event.target.value)}
                                                    type="text"
                                                />
                                            </div>
                                            <label>Enter Password </label>
                                            <div className="form-input password-input">
                                                <input
                                                    className="form-control"
                                                    name="password"
                                                    value={password}
                                                    type="text"
                                                    onChange={(event) => setpassword(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={Unauth ? "col-12 mt-2 text-center" : "col-12 mt-2"}>
                                            <CustomButton
                                                isBtn
                                                btntype="button"
                                                ClickEvent={handleSubmit}
                                                iconName="fa-solid fa-check"
                                                btnName="Submit"
                                            />
                                        </div>
                                    </>
                                )
                                    :
                                    (
                                        <div className={Unauth ? "col-12 mt-2 text-center" : "col-12 mt-2"}>
                                            <h4>Are You Sure Delete this Account</h4>
                                            <CustomButton
                                                isBtn
                                                btntype="button"
                                                ClickEvent={() => window.location.reload()}
                                                iconName="fa-solid fa-check"
                                                btnName="Cancel"
                                                extraClass={'me-4'}
                                            />

                                            <CustomButton
                                                isBtn
                                                btntype="button"
                                                ClickEvent={() => handleDeleteUser()}
                                                iconName="fa-solid fa-check"
                                                btnName="Yes"
                                            />
                                        </div>
                                    )
                            }
                        </form>
                    </DashboardBox>
                </div>
            </section>
        </main >
    );
}

export default UserDelete;
