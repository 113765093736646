import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyCg2_4u07zaOTxUiq_YhbC-19UQQdh3msw",
    authDomain: "nikkypore-47e03.firebaseapp.com",
    projectId: "nikkypore-47e03",
    storageBucket: "nikkypore-47e03.appspot.com",
    messagingSenderId: "560975586466",
    appId: "1:560975586466:web:c783e10a60785e1184e3fb"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: "BK3AOIoK79ogFZsYY-vWfM893exrz1aZfGKqed2NzMK_TPuTzoWrfd_QMgYP_nXqwZPrz4KFmqYeogIH-w5yVH8" })
        .then((currentToken) => {
            if (currentToken) {
                console.log("current token for client: ", currentToken);
                return currentToken;
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log("No registration token available. Request permission to generate one.");
                // shows on the UI that permission is required
                return null;
            }
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // catch error while creating client token
            return null;
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
